/**
 * Event-Suche
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Suchformular
 */

.form-event-search {
	padding-bottom : $gap-base;
	padding-top    : $gap-base;

	.control-label {
		font-size : cast-unit('em', $font-size-small);
	}
	
	@at-root {
		.section.-dark &,
		.section.-primary &,
		.section.-secondary & {
			.form-control {
				background-color : $color-white;
				border-color     : $color-white;
			}
		}
	}
}

/**
 * Auflistung Events
 */

.module-event-search .event-stack {
	&__item {
		flex-basis : 50%;
		min-width  : 50%;
		width      : 50%;

		@include respond-to(xs) {
			flex-basis : 100%;
			min-width  : 100%;
			width      : 100%;
		}
	}
}

