/**
 * Seiteninhalte
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

[data-comp]:not(.form-control),
.comp:not(.form-control) {
	//margin-top    : $gap-base-large;
	margin-bottom : $gap-base-large;
}

[data-comp]:last-child:not(.form-control),
.comp:last-child:not(.form-control) {
	margin-bottom : 0;
}

.-is-running {
	animation: fade-in-out 2s infinite;
}

.scroll-to-top,
[data-fixed-basket] {
	$dim : 40px;
	
	align-items     : center;
	border-radius   : 50%;
	cursor          : pointer;
	display         : flex;
	height          : $dim;
	justify-content : center;
	position        : fixed;
	right           : 15px;
	top             : 50%;
	transform       : translate3d(200px, 0, 0);
	transition      : transform .15s ease-in-out;
	width           : $dim;
	z-index         : 30;
	
	> i {
		color : inherit;
	}
	
	&:hover,
	&:focus {
		> i {
			color : $brand-primary;
		}
	}
	
	&.-show {
		transform : translate3d(0, 0, 0);
	}
}

.scroll-to-top {
	background       : $color-white;
	border           : 3px solid $brand-secondary;
	color            : $brand-secondary;
	margin-top       : -20px;
	transition-delay : 0.15s;
}

[data-fixed-basket] {
	background      : $brand-secondary;
	color           : $color-white;
	margin-top      : -70px;
	text-decoration : none !important;
	
	> .badge {
		$offset : -6px;
		background : $brand-primary;
		color      : $color-white;
		position   : absolute;
		right      : $offset;
		top        : $offset;
	}
}

.bar-account-switched {
	background-color : $brand-info;
	color            : $color-white;
	font-size        : cast-unit($font-unit-base, $font-size-small);
	padding-bottom   : $gap-base-xsmall;
	padding-top      : $gap-base-xsmall;
	text-align       : center;
	z-index          : 999;

	@supports (position: sticky) {
		left     : 0;
		position : sticky;
		width    : 100%;
	}

	a {
		@include on-event(true) {
			color : inherit;
		}
	}

	&__inner {
		@extend .page-max-width;
	}

	@include respond-to(sm) {
		font-size : cast-unit($font-unit-base, $font-size-xsmall);
	}
}

// Nutzermenü
.user-bar {
	align-items     : center;
	display         : flex;
	flex-flow       : row nowrap;
	//justify-content : space-between;
	justify-content : flex-end;
	line-height     : 1;
	text-transform  : none;

	&__col {
		flex : 1 0 auto;

		> * {
			display      : inline-block;
			margin-right : ($gap-base-large + $gap-base-small);

			&:last-child {
				margin-right : 0;
			}
		}

		&.-menu {
			text-transform: uppercase;

			.link {
				color       : $color-white;
				font-size   : 1.65rem;
				font-weight : 600;
				line-height : normal;

				i {
					display : none;
				}

				.btn__text {
					padding-left : 0;

					&-sub {
						display     : block;
						font-size   : rem(12px);
						font-weight : 400;
					}
				}

				&:hover,
				&:active,
				&:focus,
				&.active {
					text-decoration : none;
					color           : $brand-primary;
				}
			}
		}

		&.-controls {
			flex : 0 1 auto;
		}
	}

	.link,
	.btn {
		border      : 0 none;
		color       : $color-white;
		height      : auto;
		line-height : 1.75;
		padding     : ($gap-base + $gap-base-small) 0;
		position    : relative;

		i + .btn__text {
			padding-left : $gap-base;
		}

		i + i {
			padding-left : $gap-base-xsmall;
		}

		> .badge {
			background-color : $brand-info;
			font-size        : 1em;
			margin           : 0 0 0 5px;
			padding          : 3px 6px 2px;
			position         : relative;
			top              : -10px;
		}

		&:hover,
		&:active,
		&:focus,
		&.active {
			text-decoration : none;
			color           : $brand-primary;
		}
	}

	.dropdown {
		&-toggle {
			// FIX: Fehldarstellung SeaMonkey 'display:block'
			display : inline-block;
			width   : 100%;
		}

		&-menu {
			background-color : rgba($brand-secondary, 0.9);
			border           : 0 none;
			border-radius    : 0;
			color            : $color-white;
			margin           : $gap-base 0 0 0;
			min-width        : 100%;
			padding          : $gap-base;
			text-align       : center;
			transform        : translateX(43%);
			width            : auto;

			.divider {
				background-color : rgba($color-white, 0.1);
				margin-bottom    : $gap-base-xsmall;
				margin-top       : $gap-base-xsmall;
			}

			> li {
				> a {
					background-color : transparent;
					color            : $color-white;

					&:hover,
					&:active,
					&:focus {
						background-color : transparent;
						//color            : $brand-primary;
						text-decoration  : underline;
					}
				}
			}
		}
	}

	.dropdown-custom {
		&__content {
			> :first-child {
				margin-top : 0;
			}

			> :last-child {
				margin-bottom : 0;
			}

			.info {
				text-align : right;
			}
		}

		&__link {
			$-size : 56px;
			$-line-height : $-size - ceil($font-size-base / 2);

			border          : $btn-border-width $btn-border-style $btn-primary-border-color;
			border-radius   : cast-unit($font-unit-base, $-size);
			color           : $btn-primary-border-color !important;
			display         : block;
			font-weight     : $btn-font-weight;
			height          : cast-unit($font-unit-base, $-size);
			line-height     : cast-unit($font-unit-base, $-line-height);
			margin          : $gap-base-xsmall 0;
			padding         : 0 $gap-base;
			text-decoration : none;
			white-space     : nowrap;

			&:hover,
			&:focus {
				border-color : $btn-primary-border-color-hover;
				color        : $btn-primary-border-color-hover !important;
			}
		}
	}
}

.step-progress {
	// HTML-Aufbau:
	// <ol class="step-progress">
	//   <li class="step-progress__item -done"><span>Einleitung</span></li>
	//   <li class="step-progress__item -done"><span>Einleitung</span></li>
	//   <li class="step-progress__item -active"><span>Einleitung</span></li>
	//   <li class="step-progress__item"><span>Einleitung</span></li>
	// </ol>

	$step-progress-bar-height      : 4px;
	$step-progress-dot-border-size : 2px;
	$step-progress-dot-size        : $gap-base-small + ($step-progress-dot-border-size * 2);
	$step-progress-color-default   : rgba($color-black, 0.3);
	$step-progress-bgcolor-default : $color-gray;
	$step-progress-color-done      : $color-gray-dark;
	$step-progress-bgcolor-done    : $color-gray-dark;
	$step-progress-color-active    : $brand-secondary;
	$step-progress-bgcolor-active  : $brand-secondary;

	font-size       : 1rem;
	display         : flex;
	flex-flow       : row nowrap;
	justify-content : center;

	&__item {
		flex        : 0 1 50%;
		font-family : $font-family-secondary;
		margin      : 0;
		padding     : 0;
		font-weight : $font-weight-normal;

		> * {
			color          : $step-progress-color-default;
			display        : block;
			padding-bottom : cast-unit('em', ($gap-base-small * 2));
		}

		&::before,
		&::after {
			background-color : $step-progress-bgcolor-default;
			bottom           : 0;
			content          : '';
			overflow         : hidden;
			position         : absolute;
			top              : auto;
			white-space      : nowrap;
		}

		&::before {
			$-y-offset : cast-unit($font-unit-base, ceil($step-progress-dot-size / 2) - ceil($step-progress-bar-height / 2));

			border           : $step-progress-dot-border-size solid $color-white;
			border-radius    : 1em;
			bottom           : -#{$-y-offset};
			height           : cast-unit($font-unit-base, $step-progress-dot-size);
			left             : 0;
			text-indent      : 150%;
			width            : cast-unit($font-unit-base, $step-progress-dot-size);
			z-index          : 3;
		}

		&::after {
			height  : cast-unit($font-unit-base, $step-progress-bar-height);
			left    : ceil($step-progress-dot-size / 2);
			right   : 0;
			z-index : 2;
		}

		&.-done {
			> * {
				color : $step-progress-color-done;
			}

			&::before,
			&::after {
				background-color : $step-progress-bgcolor-done;
			}
		}

		&.-active {
			> * {
				color : $step-progress-color-active;
			}

			&::before {
				background-color : $step-progress-bgcolor-active;
			}
		}
	}
}

/* =============================================================================
 *
 * Formulare
 *
 * ========================================================================== */

.form {
	.form-hint {
		&,
		&-default {
			background-color : $alert-bg-color;
			color            : $alert-color;
		}

		&-info,
		&-required {
			background-color : $alert-info-bg-color;
			color            : $alert-info-color;
		}

		&-warning {
			background-color : $alert-warning-bg-color;
			color            : $alert-warning-color;
		}

		&-danger {
			background-color : $alert-danger-bg-color;
			color            : $alert-danger-color;
		}

		&-success {
			background-color : $alert-success-bg-color;
			color            : $alert-success-color;
		}
	}

	&.form-box-style {
		background-color : $color-gray-light;
		padding          : ($gap-base + $gap-base-small);
		margin-bottom    : ($gap-base * 3);

		.form-control {
			background-color : $color-white;
		}

		.bootstrap-select {
			.dropdown-toggle {
				&,
				&:focus {
					background-color : $color-white;
				}
			}

			@at-root &.open > .dropdown-toggle {
				background-color : $color-white;
			}
		}

		.form-submit-ctrl > .btn {
			min-width : 130px;
		}
	}

	&-searchbox {
		.form-heading {
			margin      : 0 0 $margin-base-vertical-large;
			white-space : nowrap;
		}

		.input-group {
			.input-group-btn {
				padding-left : $gap-base-large;
			}

			.form-control {
				border-top-right-radius    : $comp-border-radius-base;
				border-bottom-right-radius : $comp-border-radius-base;
			}

			.btn {
				$-p : $padding-base-horizontal * 2;
				border-bottom-left-radius : $input-height;
				border-top-left-radius    : $input-height;
				font-size                 : cast-unit($font-unit-base, $font-size-large);
				font-weight               : $font-weight-bold;
				padding-left              : ($-p);
				padding-right             : ($-p);

				> i {
					display : none;
				}
			}
		}
	}
}

/* =============================================================================
 *
 * Artikel
 *
 * ========================================================================== */

.article {
	align-content   : stretch;
	align-items     : stretch;
	display         : flex;
	flex-basis      : 0;
	flex-grow       : 1;
	flex-shrink     : 1;
	flex-wrap       : wrap;
	justify-content : center;
	margin          : $gap-base 0;

	> * {
		align-self     : stretch;
		flex-direction : row;
		flex-grow      : 0;
		flex-shrink    : 0;
		overflow       : hidden;
		padding        : 0;

		> *:first-child {
			margin-top : 0;
		}

		> *:last-child {
			margin-bottom : 0;
		}
	}

	&__text {
		flex-basis   : calc(75% - #{$gap-base});
		margin-right : $gap-base;
		min-width    : calc(75% - #{$gap-base});
		order        : 1;
		width        : calc(75% - #{$gap-base});

		&:first-child:last-child {
			flex-basis   : 100%;
			margin-right : 0;
			min-width    : 100%;
			width        : 100%;
		}
	}

	&__media {
		flex-basis  : calc(25% - #{$gap-base});
		margin-left : $gap-base;
		min-width   : calc(25% - #{$gap-base});
		order       : 2;
		width       : calc(25% - #{$gap-base});

		> * {
			display    : block;
			max-width  : none;
			min-height : 100%;
		}
	}

	/**
	 * Text, Logo
	 */
	&.-client-info  {
		@include respond-to(xs) {
			.article {
				&__text:not(:first-child:last-child) {
					flex-basis : calc(70% - #{$gap-base});
					min-width  : calc(70% - #{$gap-base});
					width      : calc(70% - #{$gap-base});
				}

				&__media {
					margin-top : $gap-base;
					flex-basis : calc(30% - #{$gap-base});
					min-width  : calc(30% - #{$gap-base});
					width      : calc(30% - #{$gap-base});
				}
			}
		}

		@include respond-to(c640) {
			.article {
				&__text:not(:first-child:last-child) {
					flex-basis : 100%;
					margin     : $gap-base-large 0 0;
					min-width  : 100%;
					order      : 2;
					width      : 100%;
				}

				&__media {
					flex-basis : 40%;
					min-width  : 40%;
					order      : 1;
					width      : 40%;
				}
			}
		}

		@include respond-to(c420) {
			.article__media {
				flex-basis : 60%;
				min-width  : 60%;
				width      : 60%;
			}
		}

		@at-root .client-display {
			display : none;
			margin  : $gap-base 0;

			> [data-client] {
				border-bottom : 1px solid #eee;
				display       : none;
				margin        : 0;
				padding       : $gap-base 0 $gap-base-large 0;

				> * {
					margin : 0;
				}
			}
		}
	}

	/**
	 * Einleitung Startseite
	 */

	&.-intro {
		.article {
			&__text {
				flex-basis   : calc(60% - #{$gap-base});
				min-width : calc(60% - #{$gap-base});
				width     : calc(60% - #{$gap-base});

				@include respond-to(xs) {
					flex-basis : 100%;
					margin     : 0;
					min-width  : 100%;
					width      : 100%;
				}
			}

			&__media {
				flex-basis : calc(40% - #{$gap-base});
				min-width  : calc(40% - #{$gap-base});
				width      : calc(40% - #{$gap-base});


				@include respond-to(xs) {
					flex-basis : 60%;
					margin     : $gap-base-large 0 0;
					min-width  : 60%;
					width      : 60%;
				}

				@include respond-to(c640) {
					flex-basis : 100%;
					min-width  : 100%;
					width      : 100%;
				}
			}
		}
	}
}
