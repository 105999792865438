/**
 * Buttons (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Standardbutton
 *
 * ========================================================================== */

.btn {
	@extend %transition;

	background-color : transparent;
	border           : $btn-border-width $btn-border-style transparent;
	color            : $link-color;
	font-weight      : $btn-font-weight;
	height           : $input-height;
	line-height      : $line-height-base;

	// (1)(2)
	@if (
		variable-exists(btn-font-size) and
		type-of($btn-font-size) == 'number' and
		unitless($btn-font-size) == false and
		unit($btn-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $btn-font-size);
	} @else {
		font-size : inherit;
	}

	// (1)
	@if (variable-exists(btn-text-transform) and $btn-text-transform != '') {
		text-transform : $btn-text-transform;
	}

	i + .btn-text { margin-left : $comp-margin-horizontal-small; }

	//@include button-size($comp-padding-vertical-base, $comp-padding-horizontal-base, $btn-border-radius-base);
	@include button-size(($comp-padding-horizontal-base - 2), $comp-padding-horizontal-base, $input-height);

	&.btn-flexible {
		height      : auto;
		white-space : normal;
	}

	&.hover,
	&:hover,
	&.active,
	&:active,
	&.active.hover,
	&:active:hover,
	&.active.focus,
	&:active:focus,
	&:focus,
	&.focus {
		color           : $link-color-hover;
		text-decoration : none;
	}

	&:active,
	&.active { box-shadow : none; }

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		box-shadow : none;
		cursor     : $cursor-disabled;
		opacity    : 0.25;
	}

	&.btn-circle {
		width : $input-height;

		> i { font-size : 1.25em; }
	}
}

/* =============================================================================
 *
 * Link buttons
 *
 * ========================================================================== */

// Make a button look and behave like a link
.btn-link {
	color           : $link-color;
	border-radius   : $btn-border-radius-base;
	text-decoration : $link-text-decoration;

	&,
	&:active,
	&.active,
	&[disabled],
	fieldset[disabled] & {
		box-shadow       : none;
		background-color : transparent;
	}

	&:hover,
	&:focus,
	&:active { border-color : transparent; }

	&:hover,
	&:focus {
		background-color : transparent;
		color            : $link-color-hover;
		text-decoration  : $link-text-decoration-hover;
	}

	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus {
			color           : $btn-link-disabled-color;
			text-decoration : none;
		}
	}
}

/* =============================================================================
 *
 * Alternative Buttons
 *
 * ========================================================================== */

.btn-default {
	@include button-variant($btn-default-color, $btn-default-bg-color, $btn-default-border-color, $btn-default-color-hover, $btn-default-bg-color-hover, $btn-default-border-color-hover);

	&.btn-link { @include button-variant($btn-default-bg-color, transparent, transparent, $btn-default-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($color-black, transparent, $btn-default-border-color, $btn-default-color, $btn-default-bg-color, $btn-default-bg-color); }
}

.btn-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg-color, $btn-primary-border-color, $btn-primary-color-hover, $btn-primary-bg-color-hover, $btn-primary-border-color-hover);

	&.btn-link { @include button-variant($btn-primary-bg-color, transparent, transparent, $btn-primary-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-primary-color, transparent, $btn-primary-border-color, $btn-primary-color, $btn-primary-bg-color, $btn-primary-bg-color); }
}

.btn-secondary {
	@include button-variant($btn-secondary-color, $btn-secondary-bg-color, $btn-secondary-border-color, $btn-secondary-color-hover, $btn-secondary-bg-color-hover, $btn-secondary-border-color-hover);

	&.btn-link { @include button-variant($btn-secondary-bg-color, transparent, transparent, $btn-secondary-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-secondary-bg-color, transparent, $btn-secondary-border-color, $btn-secondary-color, $btn-secondary-bg-color, $btn-secondary-bg-color); }
}

.btn-tertiary {
	@include button-variant($btn-tertiary-color, $btn-tertiary-bg-color, $btn-tertiary-border-color, $btn-tertiary-color-hover, $btn-tertiary-bg-color-hover, $btn-tertiary-border-color-hover);

	&.btn-link { @include button-variant($btn-tertiary-bg-color, transparent, transparent, $btn-tertiary-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-tertiary-bg-color, transparent, $btn-tertiary-border-color, $btn-tertiary-bg-color-hover, transparent, $btn-tertiary-border-color-hover); }
}

.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg-color, $btn-success-border-color, $btn-success-color-hover, $btn-success-bg-color-hover, $btn-success-border-color-hover);

	&.btn-link { @include button-variant($btn-success-bg-color, transparent, transparent, $btn-success-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-success-bg-color, transparent, $btn-success-border-color, $btn-success-bg-color-hover, transparent, $btn-success-border-color-hover); }
}

.btn-info {
	@include button-variant($btn-info-color, $btn-info-bg-color, $btn-info-border-color, $btn-info-color-hover, $btn-info-bg-color-hover, $btn-info-border-color-hover);

	&.btn-link { @include button-variant($btn-info-bg-color, transparent, transparent, $btn-info-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-info-bg-color, transparent, $btn-info-border-color, $btn-info-bg-color-hover, transparent, $btn-info-border-color-hover); }
}

.btn-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg-color, $btn-warning-border-color, $btn-warning-color-hover, $btn-warning-bg-color-hover, $btn-warning-border-color-hover);

	&.btn-link { @include button-variant($btn-warning-bg-color, transparent, transparent, $btn-warning-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-warning-bg-color, transparent, $btn-warning-border-color, $btn-warning-bg-color-hover, transparent, $btn-warning-border-color-hover); }
}

.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg-color, $btn-danger-border-color, $btn-danger-color-hover, $btn-danger-bg-color-hover, $btn-danger-border-color-hover);

	&.btn-link { @include button-variant($btn-danger-bg-color, transparent, transparent, $btn-danger-bg-color-hover, transparent, transparent); }

	&.btn-bordered { @include button-variant($btn-danger-bg-color, transparent, $btn-danger-border-color, $btn-danger-bg-color-hover, transparent, $btn-danger-border-color-hover); }
}

/* -----------------------------------------------------------------------------
 * Buttongrößen
 * -------------------------------------------------------------------------- */

.btn-lg {
	@include button-size($comp-padding-vertical-large, $comp-padding-horizontal-large, $input-height-large);

	height      : auto;
	line-height : $line-height-large;

	// (1)(2)
	@if (
		variable-exists(font-size-large) and
		type-of($font-size-large) == 'number' and
		unitless($font-size-large) == false and
		unit($font-size-large) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $font-size-large);
	}

	&.is-rounded { border-radius : 18px; }
}

.btn-sm {
	@include button-size($comp-padding-vertical-small, $comp-padding-horizontal-small, $input-height-small);

	height      : auto;
	line-height : $line-height-small;

	// (1)(2)
	@if (
		variable-exists(font-size-small) and
		type-of($font-size-small) == 'number' and
		unitless($font-size-small) == false and
		unit($font-size-small) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $font-size-small);
	}

	&.is-rounded { border-radius : 18px; }
}

.btn-xs {
	@include button-size($comp-padding-vertical-xsmall, $comp-padding-horizontal-xsmall, $input-height-small);

	height      : auto;
	line-height : $line-height-xsmall;

	// (1)(2)
	@if (
		variable-exists(font-size-xsmall) and
		type-of($font-size-xsmall) == 'number' and
		unitless($font-size-xsmall) == false and
		unit($font-size-xsmall) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $font-size-xsmall);
	}

	&.is-rounded { border-radius : 18px; }
}

/* =============================================================================
 *
 * Abweichende Buttondefintionen
 *
 * -----------------------------------------------------------------------------
 * Defintionen von abweichenden Komponenteneigenschaften.
 * ========================================================================== */

.btn.radio {
	label {
		min-height : 0;
	}

	input[type="radio"],
	input[type="checkbox"] { margin-top : 1px; }
}