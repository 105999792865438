/**
 * Breadcrumb
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.main-breadcrumb {
	background : $brand-primary;

	> .nav {
		$-gap : ($gap-base + $gap-base-xsmall);

		@extend .page-max-width;

		padding-bottom : $-gap;
		padding-top    : $-gap;
	}
}